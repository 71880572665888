.popup {
  width: 60%;
}

.popup__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &--input {
    color: red !important;
    width: 100%;
    // font-size: 16px;
    font-weight: bold;
    padding: 9px 20px;
    margin-top: 4px;
    margin-bottom: 8px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 0.36rem;
    box-sizing: border-box;
    background: #d9d9d9;
    opacity: 0.8;
    box-shadow: 0px 4.2px 2.4px 0px rgba(0, 0, 0, 0.25);
    height: 2.2rem;

    &option {
      font-weight: bold;
    }
  }
  &--button {
    width: 8rem;
    height: 2rem;
    background-color: #f15b24;
    color: white;
    padding: 0px 24px;
    margin-top: 4px;
    margin-bottom: 8px;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 1px 3.5px 2.7px 0px rgba(0, 0, 0, 0.25);
  }
  &--button:hover {
    background-color: #bd481d;
  }
}

@media only screen and (max-width: 646px) {
  .popup {
    width: 90%;
  }
}
