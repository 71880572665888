// ----------------- FEEDBACK SECTION ----------------

.f_just_info {
  width: 100%;
  display: flex;
  height: auto;
  padding: 0px;
  justify-content: center;
  align-items: center;
}
.feedback-title {
  height: auto;
  width: 16%;
  background-color: #f15b24;
  padding: 5px 18px;
  border-radius: 0px 7px 7px 0px;
}
.f_s_all {
  width: 84%;
  height: auto;
  padding-right: 1.5rem;
  float: right;
}
.f_cards {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.f_rating_box {
  width: 100%;
  background-color: #272361;
  box-shadow: -1px 2px 8px #ffffff;
  border-radius: 5px;
}
.feedback__slider {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1100px) {
  .f_just_info {
    font-size: 40px;
  }
  .feedback-title {
    width: 24%;
    padding: 2px 12px;
  }
  .f_s_all {
    width: 76%;
  }
}
