.logo {
  height: 100%;
  width: 100%;
  vertical-align: middle;
  // background-color: #1a164b;
}
.logo_shape a {
  pointer-events: none;
}
.logo_shape::after {
  pointer-events: all;
  content: "";
  height: 48px;
  width: 50px;
  border-radius: 50%;
  position: absolute;
  border: none;
  border-right: 3px solid #f15b24;
}
.logo_shape_for_desktop::after {
  pointer-events: none;
  content: "";
  height: 90px;
  width: 100px;
  border-radius: 50%;
  position: absolute;
  border: none;
  border-right: 4px solid #f15b24;
}
.divider_w_e::after {
  content: "";
  height: 12px;
  width: 2px;
  background-color: #f15b24;
  position: absolute;
  right: 0;
}

.nav__link--active {
  position: relative;
}
.nav__link--active::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  background-color: #f15b24;
  animation: expandBorder;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes expandBorder {
  0% {
    width: 0;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}

.nav__link:hover {
  transform: scale(1.1);
  color: rgb(225, 225, 225);
}

@media only screen and (max-width: 900px) {
  .logo_shape_for_desktop::after {
    content: "";
    height: 70px;
    width: 80px;
    border-radius: 50%;
    position: absolute;
    border: none;
    border-right: 4px solid #f15b24;
  }
}
@media only screen and (max-width: 646px) {
  .logo_shape_for_desktop::after {
    content: "";
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: absolute;
    border: none;
    border-right: 4px solid #f15b24;
  }
}
