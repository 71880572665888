.contact__map {
  width: 100%;
  .contact__map--image {
    width: 100%;
    height: 475px;
  }
}
.contact__form {
  width: 100%;
  .contact__form--container {
    width: 65%;
    margin: auto;
  }
}
/* FORM INPUT STYLE */
.form__input--text {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form__button {
  width: 100%;
  background-color: #f15b24;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}

input[type="submit"]:hover {
  background-color: #bd481d;
}

@media only screen and (max-width: 1100px) {
  .contact__map {
    width: 80%;
  }
}
@media only screen and (max-width: 900px) {
  .contact__map {
    .contact__map--image {
      height: 300px;
    }
  }
  .contact__form {
    .contact__form--container {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1536px) {
  .contact__form {
    .contact__form--container {
      width: 90%;
    }
  }
}
