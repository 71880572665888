* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.active {
  display: inline-block;
}
.inactive {
  display: none;
}
.slides {
  height: 100%;
  width: 100%;
  position: relative;
  // padding: 0px 20px;
  display: flex;
  // background-color: aqua;
  .active {
    background-color: transparent;
  }
  .slide-image {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-position: center;
    object-fit: cover;
  }
}
.prev,
.next {
  cursor: pointer;
  z-index: 20;
  position: absolute;
  top: 50%;
  padding: 0.5rem;
  margin-top: -3rem;
  font-size: 22px;
  // font-size: 30px;
  font-weight: bold;
  border-radius: 0 5px 5px 0;

  color: #f15b24;
  background-color: #272361;
}
.prev:hover,
.next:hover {
  color: white;
  background-color: rgba($color: #000000, $alpha: 0.6);
  transition: all 0.5s ease-in;
}
.prev {
  left: 0px;
  // border-radius: 5px 0 0 5px;
}
.next {
  right: 0px;
  border-radius: 5px 0 0 5px;
}
.all-dots {
  width: 100%;
  // height:492px ;
  position: absolute;
  display: flex;
  top: 94%;
  justify-content: center;
  z-index: 30;
  // background-color: red;
}
.dot {
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  margin: 0 3px;
  background-color: rgba($color: #000000, $alpha: 0.7);
  border-radius: 50%;
  display: inline-block;
}
.active-dot,
.dot:hover {
  background-color: rgba($color: #ffffff, $alpha: 1);
}
@media only screen and (max-width: 646px) {
  .dot {
    height: 0.5rem;
    width: 0.5rem;
  }
  .prev,
  .next {
    font-size: 18px;
  }
}
