* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Assistant", sans-serif;
}
.App {
  min-height: 100vh;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

/* FOR ACTIVE LISTINGS */
.active__text {
  border-bottom: 2px solid #f15b24;
}

/* REACT SLICK SLIDER */

.slick-prev,
.slick-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.slick-prev {
  left: 0px;
  z-index: 100;
}
.slick-next {
  right: 0px;
  z-index: 100;
}
.slick-slide > div {
  display: grid;
  place-items: center;
}

@media only screen and (max-width: 520px) {
  .slick-prev {
    left: 10%;
  }
  .slick-next {
    right: 10%;
  }
}

/* Progress Bar */
.progress__bar {
  // min-width: 200px;
  height: 2.5rem;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-start;
  box-shadow: 0px 8px 5px 0px rgba(0, 0, 0, 0.3);

  &--completed {
    background: #f15b24;
    width: 100%;
    color: white;
  }
  &--processing {
    background: #272361;
    width: 80%;
    color: white;
  }
  &--notcompleted {
    background: #c8c8c8;
    width: 60%;
  }
}

.completed__color {
  color: #f15b24;
}

.processing__color {
  color: #272361;
}

.notcompleted__color {
  color: #c8c8c8;
}
